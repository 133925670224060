<script>
import Layout from "../../layouts/horizontal";
import SystemValues from "@/components/system-values";
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'
import http from '@/http'

export default {
  components: {
    Layout,
    SystemValues
  },
  data() {
    return {
      dictionary:{
       assetClasses: [
          {id: 'Bonds', displayName: 'Bonds'},
          {id: 'Commodity', displayName: 'Commodity'},
          {id: 'CryptoCurrency', displayName: 'CryptoCurrency'},
          {id: 'ETF', displayName: 'ETF'},
          {id: 'Equities', displayName: 'Equities'},
          {id: 'FX', displayName: 'FX'},
          {id: 'FixedIncome', displayName: 'FixedIncome'},
          {id: 'Futures', displayName: 'Futures'},
          {id: 'FuturesCommodities', displayName: 'FuturesCommodities'},
          {id: 'FuturesRates', displayName: 'FuturesRates'},
          {id: 'MoneyMarket', displayName: 'MoneyMarket'}
        ],
        providers: [],
        rebalanceFrequency: [
          {id: 'Annual', displayName: 'Annual'},
          {id: 'Daily', displayName: 'Daily'},
          {id: 'HalfYear', displayName: 'HalfYear'},
          {id: 'Monthly', displayName: 'Monthly'},
          {id: 'Quarterly', displayName: 'Quarterly'},
          {id: 'RuleBook', displayName: 'RuleBook'},
          {id: 'Weekly', displayName: 'Weekly'}
        ],
        instrumentTypes: [
          {id: 'ActiveIndex', displayName: 'Active Index'},
          {id: 'ETF', displayName: 'ETF'},
          {id: 'PassiveIndex', displayName: 'Passive Index'},
          {id: 'Crypto', displayName: 'Crypto'},
          // {id: 'Overlay', displayName: 'Overlay'}
        ],
        indexTypes: [],
        markets: []
      },
      newLink:{
        title: null,
        link: null
      },
      item:{
        name: null,
        dispplayName: null,
        description: null,
        assetClass: null,
        provider: null,
        rebalanceFrequency: null,
        indexType: null,
        market: null,
        instrumentType: null,
        systemValues:{},
        links:[]
      },

      saving: false,
      loading: false,
      submitted: false,

      title: this.id === 'new' ? this.$t("pages.editIndex.newIndexTitle") : this.$t("pages.editIndex.editIndexTitle"),
      errors:[],
      breadcrumbs: [
        {
          text: "C8 Studio",
          to: { name: 'home' }
        },
        {
          text: this.$t("pages.indices.title"),
           to: { name: 'indices' }
        },
        {
          text: this.id === 'new' ? this.$t("pages.editIndex.newIndexTitle") : this.$t("pages.editIndex.editIndexTitle"),
          active: true
        }
      ]
    };
  },
  props: {
    id: {
      type: String,
      default: 'new',
    },
    templateId: {
      type: String,
    }
  },
  created() {
    this.loadDictionaries();
    
  },
  watch: {
    
  },
  computed: {
   
  },
  validations: {
    item: {
      displayName: { required },
      name: { required },

    }
  },
  methods: {
    addNewLink() {
      if(this.newLink.title && this.newLink.link) {
        let l = [...this.item.links, this.newLink];
        l.sort((a, b) => a.title.localeCompare(b.title));
        this.item.links = l;
        this.newLink = {
          title: null,
          link: null
        };
      }
    },
    deleteLink(link) {
      let l = [...this.item.links.filter(a => a !== link)];
      l.sort((a, b) => a.title.localeCompare(b.title));
      this.item.links = l;
    },
    async loadDictionaries() {

     
      this.loading = true;
      await this.loadDictionary('providers', 'api/providers');
      await this.loadDictionary('indexTypes', 'api/index-types');
      await this.loadDictionary('markets', 'api/markets');
      this.loading = false;
     
      this.load();
    },
    async loadDictionary(propName, apiUrl){
      const resp = await http.get(apiUrl);
      this.dictionary[propName] = resp.data;
    },
    async load(){
 
        this.loading = true;
      if(this.id && this.id !== 'new') {
        const { data } = await  http.get('api/indices/' + this.id);
        data.market = (data.market || {}).id || null;
        data.provider = (data.provider || {}).id || null;
        data.indexType = (data.indexType || {}).id || null;
        data.assetClass = data.assetClass || null;
        data.rebalanceFrequency = data.rebalanceFrequency || null;
        data.instrumentType = data.instrumentType || null;
        data.links = data.links || [];
        this.item = data;
       
      }
        this.loading = false;
    },
    save() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.saving = true;
        const dto = {
          ...this.item,
          optimization: null
        };
     
        const req = (!this.id || this.id === 'new') ? http.post('api/indices', dto) : http.put('api/indices', dto);
        req.then(() => {
          this.saving = false;
          this.$router.push({ name: "indices" });
        })
        .catch(error => {
          this.saving = false;
          this.errors = parseErrors(error);
        });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
           <span  class="title-content">
              {{ title }} <i v-if="loading"  class="fa fa-spin fa-spinner ml-2"></i>
            </span>
           </h4>

          <div class="page-title-right  d-none d-lg-block">
            <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="card-title">{{$t('pages.editIndex.basicInfo')}}</div>
            <hr/>
            <div class="form-group">
              <label for="name">{{$t('forms.indexEdit.name')}} [*]</label>
              <input
                id="name"
                v-model="item.name"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.item.name.$error }"
              />
              <div
                v-if="submitted && !$v.item.name.required"
                class="invalid-feedback"
              >{{$t('forms.indexEdit.name-required')}}</div>
            </div>
            <div class="form-group">
              <label for="displayName">{{$t('forms.indexEdit.displayName')}} [*]</label>
              <input
                id="displayName"
                v-model="item.displayName"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.item.displayName.$error }"
              />
              <div
                v-if="submitted && !$v.item.displayName.required"
                class="invalid-feedback"
              >{{$t('forms.indexEdit.displayName-required')}}</div>
            </div>
            <div v-if="item && !item.isComposed">
              <b-form-group
                class="mt-2"
                :label="$t('forms.indexEdit.indexType')"
                label-for="indexType"
              >
                <select class="form-control" v-model="item.indexType" id="indexType">
                  <option :value="null">--- Select index type ---</option>
                  <option v-for="t in dictionary.indexTypes" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>

              <b-form-group
                class="mt-2"
                :label="$t('forms.indexEdit.instrumentType')"
                label-for="instrumentType"
              >
                <select class="form-control" v-model="item.instrumentType" id="instrumentType">
                  <option :value="null">--- Select instrument type ---</option>
                  <option v-for="t in dictionary.instrumentTypes" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>

              <b-form-group
                class="mt-2"
                :label="$t('forms.indexEdit.provider')"
                label-for="provider"
              >
                <select class="form-control" v-model="item.provider" id="provider">
                  <option :value="null">--- Select provider ---</option>
                  <option v-for="t in dictionary.providers" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>

              <b-form-group
                  class="mt-2"
                  :label="$t('forms.indexEdit.market')"
                  label-for="market"
                >
                <select class="form-control" v-model="item.market" id="market">
                  <option :value="null">--- Select market ---</option>
                  <option v-for="t in dictionary.markets" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>

              <b-form-group
                  class="mt-2"
                  :label="$t('forms.indexEdit.rebalanceFrequency')"
                  label-for="rebalanceFrequency"
                >
                <select class="form-control" v-model="item.rebalanceFrequency" id="rebalanceFrequency">
                  <option :value="null">--- Select rebalance frequency ---</option>
                  <option v-for="t in dictionary.rebalanceFrequency" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>

              <b-form-group
                  class="mt-2"
                  :label="$t('forms.indexEdit.assetClass')"
                  label-for="assetClass"
                >
                <select class="form-control" v-model="item.assetClass" id="assetClass">
                  <option :value="null">--- Select asset class ---</option>
                  <option v-for="t in dictionary.assetClasses" :value="t.id" :key="t.id">
                    {{t.displayName}}
                  </option>
                </select>
              </b-form-group>
            </div>
            
          </div>
          <div class="col-lg-6">
            <div class="card-title">{{$t('pages.editIndex.systemValues')}}</div>
            <hr/>
            <system-values :values="item.systemValues" @change="o => item.systemValues = o"></system-values>
            <div class="card-title mt-4">{{$t('pages.editIndex.links')}}</div>
            <hr/>
           
            <div class="table-responsive" v-if="item">
              <table
                class="table table-centered table-sm datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%; position: relative;"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 30px;max-width:30px;position: sticky;top: 0;">
                      <a
                        style="margin-left:5px"
                        href="javascript:void(0);"
                        class="text-primary"
                        :disable="!newLink.title && !newLink.link"
                        v-b-tooltip.hover
                        @click="addNewLink()"
                      >
                        <i class="fa fa-plus font-size-18"></i>
                      </a>
                    </th>
                    <th style="position: sticky;top: 0;"> <input type="text" class="form-control input-sm" v-model="newLink.title" /></th>
                    <th style="position: sticky;top: 0;"> <input type="text" class="form-control input-sm" v-model="newLink.link"/></th>
                    <th style="width: 30px;max-width:30px;position: sticky;top: 0;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="item && item.links.length === 0">
                    <td colspan="100">
                      <span class="text-muted">{{$t("common.noRecordsFound")}}</span>
                    </td>
                  </tr>
                
                  <tr v-for="(link, index) in item.links" :key="index">
                    <td></td>
                    <td>
                      <span>{{link.title}}</span>
                    </td>
                    <td>
                      <span>{{link.link}}</span>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        title="Delete"
                        @click="deleteLink(link)"
                      >
                        <i class="fa fa-times font-size-18"></i>
                      </a>
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
      </div>
      
      <div class="card-footer">
        <div class="row">
          <div class="col-md-6">
            <router-link :to="{ name: 'indices'}" class="py-1 mr-4"> <i class="fa fa-angle-double-left mr-1"></i> Cancel</router-link> 
          </div>
          <div class="col-md-6 text-right">
            <b-button variant="primary" class="py-1" @click="save" :disabled="loading || saving">
              <i class="fa fa-spinner" v-if="saving"></i>
              {{$t("common.save")}}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    
  </Layout>
</template>